import { Card, CardContent, Divider } from "@mui/material";
import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRightMenu } from "../../components/Layout";
import { useMsg } from "../../components/Msg/Msg";
import { FeedbackRightMenu } from "./FeedbackRightMenu";
import { FormBuilderFields } from "./FormBuilderFields";
// import { FormBuilderMeta } from "./FormBuilderMeta";
import { DEFAULT_VALUES, FEEDBACK_FIELDS } from "./constants";
import { messages } from "./messages";
import {
  any,
  equals,
  evolve,
  identity,
  map,
  pick,
  pipe,
  prop,
  propEq,
  reject,
  trim,
} from "ramda";
import { useFeedbackFormsQuery } from "./api";
import { generalMessages } from "../../components/messages";
import { Icon } from "../../components/Icon";
import { RHFTextField } from "../../components/Forms";

// const onSubmit = (data, e) => console.log("[onSubmit]", data, e);
const onError = (errors, e) => console.log("[onError]", { errors, e });

export const CreateFeedbackForm = ({
  isEdit,
  initialValues,
  onShareForm,
  onSaveDraft,
  isPending,
  isSavingDraft,
  collected,
}) => {
  const msg = useMsg({ dict: messages });
  const generalMsg = useMsg({ dict: generalMessages });
  const defaultValues = { ...DEFAULT_VALUES, ...initialValues };
  const form = useForm({
    // Both defined - error (uncontrolled -> controlled) in console
    defaultValues: defaultValues,
    values: defaultValues,
  });
  const fields = form.watch(FEEDBACK_FIELDS.fields);
  const count = fields?.length;
  const requiredCount = fields?.filter((field) => field.required)?.length;
  const _onShareForm = useCallback(
    (values) =>
      pipe(evolve({ [FEEDBACK_FIELDS.title]: trim }), onShareForm)(values),
    [onShareForm]
  );
  const _onSaveDraft = useCallback(
    (values) =>
      pipe(evolve({ [FEEDBACK_FIELDS.title]: trim }), onSaveDraft)(values),
    [onSaveDraft]
  );
  const feedbacksQuery = useFeedbackFormsQuery();
  const existingTitles = useMemo(() => {
    if (!feedbacksQuery.data?.length) return [];
    else
      return pipe(
        isEdit ? reject(propEq(defaultValues.id, "id")) : identity,
        map(prop(FEEDBACK_FIELDS.title))
      )(feedbacksQuery.data);
  }, [feedbacksQuery.data, defaultValues?.id, isEdit]);

  useRightMenu(
    useMemo(
      () => (
        <FeedbackRightMenu
          collected={collected}
          stats={[
            { label: msg("feedback.create.stats.questions"), value: count },
            {
              label: msg("feedback.create.stats.required"),
              value: requiredCount,
            },
          ]}
          secondaryButtonProps={{
            endIcon: <Icon name="ArchiveOutlined" />,
            children: generalMsg("general.save-draft"),
            onClick: form.handleSubmit(_onSaveDraft, onError),
            disabled: isPending,
            loading: isSavingDraft,
            loadingPosition: "end",
          }}
          buttonProps={{
            endIcon: <Icon name="SendOutlined" />,
            children: msg("feedback.create.next-btn"),
            onClick: form.handleSubmit(_onShareForm, onError),
            disabled: isPending,
            // loadingPosition: "end",
          }}
        />
      ),
      [
        collected,
        msg,
        count,
        requiredCount,
        generalMsg,
        form,
        _onSaveDraft,
        isPending,
        isSavingDraft,
        _onShareForm,
      ]
    )
  );

  console.log(
    "[CreateFeedbackForm.rndr]",
    defaultValues[FEEDBACK_FIELDS.title],
    {
      significant: pick(
        [FEEDBACK_FIELDS.title, FEEDBACK_FIELDS.description],
        defaultValues
      ),
      defaultValues,
    }
  );

  return (
    <FormProvider {...form}>
      <Card>
        <CardContent>
          <RHFTextField
            name={FEEDBACK_FIELDS.title}
            placeholder={msg("feedback.create.title.placeholder")}
            parametrizedValidate={[
              ["required"],
              ["notBlank"],
              [
                "forbiddenValues",
                { forbiddenList: existingTitles },
                { tsKey: "feedback.create.title.error.exists" },
              ],
            ]}
            variant="standard"
            fullWidth
            debug={{ msg: "OMG" }}
            // sx={{ mt: 3 }}
          />
          <RHFTextField
            name={FEEDBACK_FIELDS.description}
            placeholder={msg("feedback.create.description.placeholder")}
            rules={{}}
            variant="standard"
            fullWidth
            sx={{ mt: 3 }}
          />
        </CardContent>
      </Card>
      <Divider sx={{ my: 3 }} />
      <FormBuilderFields name={FEEDBACK_FIELDS.fields} />
    </FormProvider>
  );
};
