import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { H1, P } from "../../components/Typography";
import { routes } from "../../routes";
import { useAuth } from "../Authorization/AuthProvider";
import { I18nContext } from "../I18n/I18nProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { CreateFeedbackForm } from "./CreateFeedbackForm";
import { getCollectedMaybe } from "./GetFeedback.page";
import {
  FIELD_DEFAULT_VALUES,
  SHARE_FIELDS,
  ShareFeedbackModal,
} from "./ShareFeedbackModal";
import {
  useCreateFeedbackFormMutation,
  useFeedbackQuery,
  useUpdateFeedbackFormMutation,
} from "./api";
import { FEEDBACK_FIELDS } from "./constants";
import { messages } from "./messages";
import { generalMessages } from "../../components/messages";
import { Box } from "@mui/material";

const from = ({
  shareFormValues,
  formBuilderValues,
  username,
  language,
  draft = false,
}) => {
  const validTo = shareFormValues.validTo;
  const payload = {
    id: formBuilderValues.id,
    title: formBuilderValues.title,
    description: formBuilderValues.description,
    username,
    validTo,
    questions: formBuilderValues.fields.map(
      ({ title, inputType, required }) => ({
        key: title,
        type: inputType,
        required,
      })
    ),
    recipients: shareFormValues.emailList.map(({ id, email, submitted }) => ({
      id,
      submitted: !!submitted,
      username: email,
    })),
    locale: language?.substring(0, 2), // TODO: just 4 supported languages - BE
    draft,
  };
  return payload;
};

const to = (data, { i18n }) => {
  return data
    ? {
        ...data,
        [FEEDBACK_FIELDS.fields]: data.questions.map(
          ({ key, type, required }) => ({
            title: key,
            inputType: type,
            required,
          })
        ),
        validTo: data.validTo && i18n.parseUTCLocal(data.validTo),
        emailList: data.recipients.length
          ? data.recipients.map(({ username, id, submitted }) => ({
              id,
              submitted,
              email: username,
            }))
          : [FIELD_DEFAULT_VALUES],
      }
    : undefined;
};

function CreateFeedbackPageInner({ data, isCopy, isEdit }) {
  const msg = useMsg();
  const generalMsg = useMsg({ dict: generalMessages });
  const navigate = useNavigate();
  const { user } = useAuth();
  const { i18n, language } = useContext(I18nContext);

  const [formBuilderValues, setFormBuilderValues] = useState();

  const initialValues = useMemo(() => to(data, { i18n }), [data, i18n]);
  const collected = useMemo(() => getCollectedMaybe(data), [data]);

  const createMutation = useCreateFeedbackFormMutation({
    onSuccess: () => {
      setFormBuilderValues();
      navigate(routes.getFeedback);
    },
  });
  const updateMutation = useUpdateFeedbackFormMutation({
    params: { id: data?.id },
    onSuccess: () => {
      setFormBuilderValues();
      navigate(routes.getFeedback);
    },
  });
  const mutation = isEdit ? updateMutation : createMutation;

  console.log("%c[CreateFeedbackPageInner.rndr]", "color:lime", {
    mutation,
    isEdit,
    data,
    initialValues,
  });

  const handleShareForm = useCallback((formBuilderValues) => {
    console.log("[CreateFeedbackPageInner.handleShareForm]", {
      formBuilderValues,
    });
    return setFormBuilderValues(formBuilderValues);
  }, []);
  const handleSaveDraft = useCallback(
    (formBuilderValues) => {
      const payload = from({
        shareFormValues: {
          [SHARE_FIELDS.emailList]: [],
          [SHARE_FIELDS.validTo]: null,
          // [SHARE_FIELDS.validTo]: new Date(
          //   Date.now() + 30 * 24 * 60 * 60 * 1000 // TODO
          // ),
        },
        formBuilderValues,
        language,
        username: user.data.username,
        draft: true,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [language, mutation, user.data.username]
  );
  const handleSubmit = useCallback(
    (shareFormValues) => {
      const payload = from({
        shareFormValues,
        formBuilderValues,
        language,
        username: user.data.username,
        draft: false,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [formBuilderValues, user.data.username, language, mutation]
  );
  const handleSubmitDraft = useCallback(
    (shareFormValues) => {
      const payload = from({
        shareFormValues,
        formBuilderValues,
        language,
        username: user.data.username,
        draft: true,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [formBuilderValues, user.data.username, language, mutation]
  );
  const isPending = mutation.isPending;
  const isSavingDraft = mutation.isPending && mutation.variables?.draft;
  const isSubmitting = mutation.isPending && !mutation.variables?.draft;

  return (
    <>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <H1 sx={{ flex: 1 }}>{msg("feedback.heading")}</H1>
        <P>
          {initialValues?.draft || isEdit
            ? generalMsg("general.draft")
            : isCopy
            ? generalMsg("general.copy")
            : ""}
        </P>
        {/* TODO: initialValues?.draft always false, BE - @jk */}
      </Box>
      <CreateFeedbackForm
        initialValues={initialValues}
        onShareForm={handleShareForm}
        onSaveDraft={handleSaveDraft}
        collected={!isCopy && !isEdit && collected}
        isEdit={isEdit}
        isPending={isPending}
        isSavingDraft={isSavingDraft}
      />
      {!!formBuilderValues && ( // TODO: Modal with form reinit
        <ShareFeedbackModal
          open={!!formBuilderValues}
          onSubmit={handleSubmit}
          onSaveDraft={handleSubmitDraft}
          onClose={() => setFormBuilderValues()}
          //   link="http://topleader.io/juRcHHx7r8QTPYP"
          link=""
          isPending={isPending}
          isSubmitting={isSubmitting}
          isSavingDraft={isSavingDraft}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

export function CreateFeedbackPage({ isCopy, isEdit }) {
  const { id } = useParams();
  const generalMsg = useMsg({ dict: generalMessages });
  const query = useFeedbackQuery({
    params: { id },
    // gcTime: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  console.log("[CreateFeedbackPage.rndr]", { id });

  const renderContent = () => {
    if (!id) return <CreateFeedbackPageInner />;

    return (
      <QueryRenderer
        query={query}
        loaderEl={<Loaders.CircularBlock spaced />}
        success={({ data, isFetching }) => {
          console.log(">>>>>>>>>>", { isFetching, data });
          // if (isFetching) return null;
          return (
            <CreateFeedbackPageInner
              // key={JSON.stringify(data)} // not working as query is always returning wrong data
              data={data}
              isCopy={isCopy}
              isEdit={isEdit}
            />
          );
        }}
      />
    );
  };

  return (
    <MsgProvider messages={messages}>
      <Layout>
        <Header
          text={generalMsg("general.back")}
          back={{ href: routes.getFeedback }}
        />
        {renderContent()}
      </Layout>
    </MsgProvider>
  );
}
