import { Autocomplete, Button, TextField } from "@mui/material";
import { useState } from "react";
import { Icon } from "../../components/Icon";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { TLCell } from "../../components/Table/TLLoadableTable";
import { TLTableWithHeader } from "../../components/Table/TLTableWithHeader";
import { CompanyModal } from "./Admin/CompanyModal";
import { useCompaniesQuery, useCompanyEditMutation } from "./Admin/api";
import { messages } from "./messages";
import { ratesOptions } from "../Coaches/CoachesFilter";

function CompaniesSettingsInner() {
  const [company, setCompany] = useState();
  const msg = useMsg();
  const companiesQuery = useCompaniesQuery();
  const companyEditMutation = useCompanyEditMutation(); // confirm modal?

  const columns = [
    {
      label: "ID",
      key: "id",
      render: (row) => <TLCell variant="emphasized" name={row.id || ""} />,
    },
    {
      label: "Name",
      key: "name",
      render: (row) => <TLCell variant="emphasized" name={row.name || ""} />,
    },
    {
      label: "Visibility of coaches",
      key: "defaultAllowedCoachRate", // allowedCoachRates
      render: (row) => (
        <Autocomplete
          multiple
          disableClearable
          value={row.defaultAllowedCoachRate}
          name={"defaultAllowedCoachRate"}
          options={ratesOptions.map((option) => option.value)}
          placeholder=""
          onChange={(e, data, action, extra) =>
            console.log({ data, e, action, extra }) ||
            companyEditMutation.mutate({
              ...row,
              defaultAllowedCoachRate: data,
            })
          }
          disabled={companyEditMutation.isPending}
          size="small"
          renderInput={(params) => <TextField {...params} />}
        />
        // <TLCell variant="emphasized" name={row.defaultAllowedCoachRate || ""} />
      ),
    },
  ];

  return (
    <>
      <TLTableWithHeader
        titleDef={{
          heading: "Companies",
          chip: `${companiesQuery.data?.length} companies`,
          chipVisible: !!companiesQuery.data,
        }}
        columns={columns}
        query={companiesQuery}
        action={
          <Button
            variant="contained"
            startIcon={<Icon name="Add" />}
            onClick={() => {
              setCompany({});
            }}
          >
            Add
          </Button>
        }
      />
      <CompanyModal
        open={!!company}
        initialValues={company}
        onClose={() => setCompany(false)}
      />
    </>
  );
}

export function CompaniesSettings() {
  return (
    <MsgProvider messages={messages}>
      <CompaniesSettingsInner />
    </MsgProvider>
  );
}
