import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { useEffect, useState } from "react";
// import { Droppable, DroppableProps } from "react-beautiful-dnd";

// https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4
// Credits to https://github.com/GiovanniACamacho and https://github.com/Meligy for the TypeScript version
// Original post: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194

// no hand cursor on item before first drag
const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const Wrapper = ({
  rhfMove,
  onDragEnd = (result) => {
    const { source, destination } = result;
    if (destination) rhfMove(source.index, destination.index);
  },
  droppableId = "items",
  children,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            id="droppable-wrapper"
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Item = ({ index, draggableId, children, isDragDisabled }) => {
  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={draggableId}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          id="draggable-item"
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};
export const ListDnD = {
  Wrapper,
  Item,
};
