import {
  AppBar,
  Avatar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { gray50 } from "../theme";
import { Header } from "./Header";
import { Icon } from "./Icon";
import { MainMenu } from "./MainMenu";

const drawerWidth = 256;

const openedMixin = ({ theme, width }) => ({
  width: width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = ({ theme, width }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: width || `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: width || `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !["open", "toggleMobile"].includes(prop),
})(({ theme, open, width, toggleMobile, ...rest }) => ({
  width: width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  position: "relative",
  "& .MuiDrawer-paper": {
    ...(toggleMobile ? { overflow: "visible" } : {}),
    boxSizing: "border-box",
  },
  ...(open && {
    ...openedMixin({ theme, width }),
    "& .MuiDrawer-paper": openedMixin({ theme, width }),
  }),
  ...(!open && {
    ...closedMixin({ theme, width }),
    "& .MuiDrawer-paper": closedMixin({ theme, width }),
  }),
}));

const SideMenu = ({
  children,
  width,
  anchor,
  toggleMobile,
  open,
  printHidden,
}) => {
  const printSx = printHidden
    ? {
        "@media print": {
          display: "none",
        },
      }
    : {};

  return (
    <StyledDrawer
      // elevation={1} // TODO: discuss & overflow?
      id="side-menu-left"
      width={width}
      variant="permanent"
      anchor={anchor}
      open={open}
      toggleMobile={toggleMobile}
      sx={printSx}
    >
      {children}
      {toggleMobile ? (
        <Box
          sx={{
            position: "absolute",
            ...(anchor === "left" ? { right: "-20px" } : { left: "-20px" }),
            top: "55px",
          }}
        >
          {toggleMobile}
        </Box>
      ) : null}
    </StyledDrawer>
  );
};

export const RightMenuContext = createContext();

const update =
  ({ id, render }) =>
  (prev) => {
    const index = prev.findIndex((item) => item.id === id);
    let newStack;
    if (!render) {
      // console.log("%cUPDATE STACK", "color:pink;", "REMOVE");
      newStack = prev.filter((item) => item.id !== id);
    } else if (index < 0 && render) {
      // console.log("%cUPDATE STACK", "color:pink;", "ADD");
      newStack = [...prev, { id, render }];
    } else {
      // console.log("%cUPDATE STACK", "color:pink;", "UPDATE");
      newStack = [...prev];
      newStack[index] = { id, render };
      // newStack = [...prev].splice(index, 0, { id, render });
    }
    return newStack;
  };

export const RightMenuProvider = ({ children }) => {
  const [stack, setStack] = useState([]);
  const context = useMemo(
    () => ({
      updateStack: ({ id, render }) => setStack(update({ id, render })),
      stack,
    }),
    [stack]
  );
  // console.log("[RightMenuProvider]", stack);

  return (
    <RightMenuContext.Provider value={context}>
      {children}
    </RightMenuContext.Provider>
  );
};

let counter = 0;

export const useRightMenu = (elementOrFn) => {
  const [id] = useState(() => counter++);
  const { updateStack } = useContext(RightMenuContext);

  const updateStackRef = useRef(updateStack);
  updateStackRef.current = updateStack;

  useEffect(() => {
    const render =
      typeof elementOrFn === "function"
        ? elementOrFn
        : elementOrFn
        ? () => elementOrFn
        : undefined;
    // console.log("%c[useRightMenu.eff]", "color:coral;", { id, element });
    updateStackRef.current({ id, render });
  }, [elementOrFn, id]);

  useEffect(
    () => () => {
      // console.log("%c[useRightMenu.eff cleanup]", "color:coral;", { id });
      updateStackRef.current({ id, render: null });
    },
    [id]
  );
};

const LayoutHeader = ({
  avatarSrc,
  heading,
  noDivider,
  withNotifications,
  ...props
}) => {
  return (
    <Header
      avatar={
        avatarSrc && (
          <Avatar variant="circular" src={avatarSrc} sx={{ mr: 2 }} />
        )
      }
      text={heading}
      noDivider={noDivider}
      withNotifications={withNotifications}
      {...props}
    />
  );
};

export const LayoutCtx = createContext({});

export const usePrintWithoutLeftMenu = ({
  setPrintLeftMenuHidden: setPrintLeftMenuHiddenProp,
} = {}) => {
  const { setPrintLeftMenuHidden = setPrintLeftMenuHiddenProp } =
    useContext(LayoutCtx);

  // Firefox printing fix: https://mui.com/material-ui/react-use-media-query/
  // https://bugzilla.mozilla.org/show_bug.cgi?id=774398
  // const isPrint = useMediaQuery("print");

  useLayoutEffect(() => {
    // setPrintLeftMenuHidden(isPrint);
    setPrintLeftMenuHidden(true);
    return () => {
      setPrintLeftMenuHidden(false);
    };
  }, [setPrintLeftMenuHidden]);
};

export const Layout = ({
  children,
  header,
  rightMenuContent: rightMenuContentProp,
  initialPrintLeftMenuHidden = false,
  sx = {},
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const [printLeftMenuHidden, setPrintLeftMenuHidden] = useState(
    initialPrintLeftMenuHidden
  );
  // usePrintWithoutLeftMenu({ setPrintLeftMenuHidden });

  const [leftOpen, setLeftOpen] = useState(!downLg);
  useEffect(() => {
    setLeftOpen(!downLg);
  }, [downLg]);
  const handleToggleLeft = useCallback(() => {
    setLeftOpen((leftOpen) => !leftOpen);
  }, []);

  const [rightOpen, setRightOpen] = useState(!downMd);
  useEffect(() => {
    setRightOpen(!downMd);
  }, [downMd]);
  const handleToggleRight = useCallback(() => {
    setRightOpen((rightOpen) => !rightOpen);
  }, []);

  const { stack } = useContext(RightMenuContext);
  const rightMenuContent = useMemo(() => {
    // const propMenu = typeof rightMenuContentProp === "function" ? rightMenuContentProp({}) : rightMenuContentProp
    const lastStackItem = stack[stack.length - 1];
    return (
      rightMenuContentProp ||
      lastStackItem?.render?.({ rightOpen, setRightOpen })
    );
  }, [rightMenuContentProp, rightOpen, stack]);
  const leftWidth = leftOpen ? 256 : 88;
  const rightWidth = rightOpen ? (downLg ? 300 : 392) : 12;
  // console.log("[Layout.rndr]", { stack, rightMenuContent });

  return (
    <LayoutCtx.Provider
      value={{ downLg, downMd, printLeftMenuHidden, setPrintLeftMenuHidden }}
    >
      <Box
        id="layout"
        sx={{
          display: "flex",
          bgcolor: gray50,
          minHeight: "100%",
          overflow: "auto",
        }}
      >
        <SideMenu
          width={leftWidth}
          anchor="left"
          open={leftOpen}
          printHidden={printLeftMenuHidden}
          toggleMobile={
            !downLg ? null : (
              <IconButton
                variant="outlined"
                size="small"
                disableRipple
                sx={{ backgroundColor: "white", border: "1px solid #EAECF0" }}
                onClick={handleToggleLeft}
              >
                <Icon name={leftOpen ? "ArrowBack" : "ArrowForward"} />
              </IconButton>
            )
          }
        >
          <MainMenu open={leftOpen} />
        </SideMenu>

        <Box
          component="main"
          sx={{
            px: 3,
            pb: 3, // TODO: check everywhere
            overflow: "auto",
            width: "100%",
            flexGrow: 1,
            position: "relative",
            ...sx,
          }}
        >
          {header && <LayoutHeader {...header} />}
          {children}
        </Box>

        {rightMenuContent && (
          <SideMenu
            width={rightWidth}
            anchor="right"
            open={rightOpen}
            toggleMobile={
              !downMd ? null : (
                <IconButton
                  variant="outlined"
                  size="small"
                  disableRipple
                  sx={{ backgroundColor: "white", border: "1px solid #EAECF0" }}
                  onClick={handleToggleRight}
                >
                  <Icon name={rightOpen ? "ArrowForward" : "ArrowBack"} />
                </IconButton>
              )
            }
          >
            {rightMenuContent}
          </SideMenu>
        )}
      </Box>
    </LayoutCtx.Provider>
  );
};
