import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { Msg, MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { routes } from "../../routes";
import { I18nContext } from "../I18n/I18nProvider";
import { QueryRenderer } from "../QM/QueryRenderer";
import { StepperRightMenu } from "./NewSession";
import { SessionStepCard } from "./SessionStepCard";
import { useUserReflectionMutation, useUserSessionQuery } from "./api";
import { sessionsMessages } from "./messages";
import { DEFAULT_VALUE_ROW } from "./steps/ActionStepsStep";
import { Finished } from "./steps/Finished";
import { useEditSteps, useSteps } from "./steps/useSessionSteps";

function EditSessionPageInner() {
  const [adjust, setAdjust] = useState(false);
  const { i18n } = useContext(I18nContext);
  const { id } = useParams();
  const msg = useMsg();
  const navigate = useNavigate();

  const query = useUserSessionQuery({});
  const mutation = useUserReflectionMutation({
    adjust,
  });

  const { steps } = useEditSteps({ adjust });
  const {
    activeStep: { StepComponent = SessionStepCard, ...activeStep } = {},
    activeStepIndex,
    handleNext,
    handleBack,
    data,
    setData,
  } = useSteps({
    steps,
    initialIndex: 0,
    initialData: {
      actionSteps: DEFAULT_VALUE_ROW,
      previousActionSteps: [],
    },
  });

  console.log("[EditSessionPage.rndr]", {
    isFetching: query.isFetching,
    id,
    data,
    activeStepIndex,
    steps,
    activeStep,
    adjust,
  });

  return (
    <Layout
      rightMenuContent={
        <StepperRightMenu
          heading={
            <>
              <Msg id="sessions.new.aside.title" />
              &nbsp; {i18n.formatLocalMaybe(new Date(), "P")}
            </>
          }
          activeStepIndex={activeStepIndex}
          steps={steps}
        />
      }
    >
      <Header
        // text={<Msg id="sessions.new.header" />}
        // back={{ href: routes.sessions }}
        actionButton={{
          variant: "text",
          color: "error",
          children: msg("sessions.new.aside.end-button"),
          onClick: () => navigate(routes.sessions),
          startIcon: <Icon name="StopCircle" />,
        }}
      />
      {query.isFetching ? (
        <QueryRenderer isPending loaderName="Block" />
      ) : mutation.isSuccess ? (
        <Finished />
      ) : (
        <StepComponent
          step={activeStep}
          stepper={{ currentIndex: activeStepIndex, totalCount: steps.length }}
          data={data}
          setData={setData}
          handleNext={handleNext}
          handleBack={handleBack}
          onFinish={mutation.mutate}
          motivation={query.data?.motivation}
          lastReflection={query.data?.lastReflection}
          previousActionSteps={query.data?.actionSteps ?? []}
          previousArea={query.data?.areaOfDevelopment ?? ""}
          previousGoal={query.data?.longTermGoal ?? ""}
          setAdjust={setAdjust}
          submitDisabled={mutation.isPending}
        />
      )}
    </Layout>
  );
}

export function EditSessionPage() {
  return (
    <MsgProvider messages={sessionsMessages}>
      <EditSessionPageInner />
    </MsgProvider>
  );
}
