import { Box, Divider } from "@mui/material";
import { defineMessages } from "react-intl";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { H1 } from "../../components/Typography";
import { useUserUpcomingSessionsQuery } from "../Coaches/api";
import { ScheduledSessionsCard } from "../Coaches/ScheduledSessions";
import { QueryRenderer } from "../QM/QueryRenderer";

const messages = defineMessages({
  "myCalendar.title": {
    id: "myCalendar.title",
    defaultMessage: "Calendar",
  },
});

const MyCalendarPageInner = () => {
  const msg = useMsg({ dict: messages });
  const userUpcomingSessionsQuery = useUserUpcomingSessionsQuery();

  return (
    <Layout>
      <Box mt={4} mb={3}>
        <Box
          display="flex"
          flexWrap="nowrap"
          alignItems="center"
          flexDirection="row"
        >
          <H1>{msg("myCalendar.title")}</H1>
        </Box>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 3 }} />
      </Box>

      <QueryRenderer
        {...userUpcomingSessionsQuery}
        success={({ data }) => <ScheduledSessionsCard data={data} />}
      />
    </Layout>
  );
};

export const MyCalendarPage = () => {
  return (
    <MsgProvider messages={messages}>
      <MyCalendarPageInner />
    </MsgProvider>
  );
};
