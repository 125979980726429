import { descend, evolve, map, pick, pipe, prop, sort, trim } from "ramda";
import { useRef } from "react";
import {
  useAuth,
  useMyMutation,
  useMyQuery,
} from "../Authorization/AuthProvider";
import { EXTERNAL_FEEDBACK_FIELDS } from "./constants";

export const useFeedbackOptionsQuery = (rest = {}) => {
  const query = useMyQuery({
    retry: 1,
    refetchOnWindowFocus: false,
    queryKey: ["feedback", "options"],
    fetchDef: { url: `/api/public/latest/feedback/options` },
    ...rest,
  });
  return query;
};

export const useExternalFeedbackQuery = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const externalFeedbackQuery = useMyQuery({
    queryKey: ["feedback", "external"],
    fetchDef: {
      url: `/api/public/latest/feedback/${formId}/${username}/${token}`,
    },
    retry: 1,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return externalFeedbackQuery;
};

export const useExternalFeedbackMutation = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/public/latest/feedback/${formId}/${username}/${token}`,
      from: evolve({
        [EXTERNAL_FEEDBACK_FIELDS.answers]: map(
          pipe(pick(["question", "answer"]), evolve({ answer: trim }))
        ),
      }),
    },
    ...rest,
  });
  return mutation;
};

export const useRequestAccessMutation = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/public/latest/feedback/request-access/${formId}/${username}/${token}`,
    },
    ...rest,
  });
  return mutation;
};

export const useCreateFeedbackFormMutation = (rest = {}) => {
  const mutation = useMyMutation({
    fetchDef: { method: "POST", url: "/api/latest/feedback" },
    invalidate: [{ exact: false, queryKey: ["feedback"] }],
    ...rest,
  });
  return mutation;
};

export const useUpdateFeedbackFormMutation = ({
  params: { id },
  ...rest
} = {}) => {
  const mutation = useMyMutation({
    debug: true,
    fetchDef: { method: "PUT", url: `/api/latest/feedback/${id}` },
    invalidate: [{ queryKey: ["feedback"] }],
    removeQueries: [{ queryKey: ["feedback", `${id}`] }],
    // refetchQueries: [{ queryKey: ["feedback", `${id}`] }], // not saved to cache?!?
    ...rest,
  });
  return mutation;
};

export const useFeedbackQuery = ({ params: { id }, ...rest } = {}) => {
  const query = useMyQuery({
    debug: true,
    enabled: !!id,
    queryKey: ["feedback", `${id}`],
    fetchDef: { url: `/api/latest/feedback/${id}` },
    ...rest,
  });
  // console.log("useFeedbackQuery", { id, query });
  return query;
};

export const useDeleteFeedbackFormMutation = (rest = {}) => {
  return useMyMutation({
    fetchDef: {
      method: "DELETE",
      getUrl: ({ id }) => `/api/latest/feedback/${id}`,
    },
    invalidate: [{ exact: false, queryKey: ["feedback", "list"] }],
    ...rest,
  });
};

export const useFeedbackFormsQuery = ({ ...rest } = {}) => {
  const username = useAuth().user.data.username;
  const query = useMyQuery({
    queryKey: ["feedback", "list", username],
    fetchDef: {
      url: `/api/latest/feedback/user/${username}`,
      to: sort(descend(prop("createdAt"))),
    },
    ...rest,
  });
  return query;
};

export const useFeedbackResultsQuery = ({ params: { id }, ...rest } = {}) => {
  const query = useMyQuery({
    queryKey: ["feedback", "results", id],
    fetchDef: { url: `/api/latest/feedback/${id}` }, // TODO: currently same as useFeedbackQuery
    ...rest,
  });
  return query;
};
