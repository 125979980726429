import { Divider, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import { Msg } from "../../components/Msg";
import { ProgressStats } from "../../components/ProgressStats";
import { ScrollableRightMenu } from "../../components/ScrollableRightMenu";
import { P } from "../../components/Typography";
import { Loaders } from "../QM/QueryRenderer";
import { useMsg } from "../../components/Msg/Msg";
import { messages } from "./messages";

const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  backgroundColor: "#EAECF0",
}));

export const FeedbackRightMenu = ({
  buttonProps,
  secondaryButtonProps,
  stats,
  collected,
  isPending,
  children,
}) => {
  const msg = useMsg({ dict: messages });
  return (
    <ScrollableRightMenu
      heading={msg("feedback.aside.heading")}
      buttonProps={buttonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <P gutterBottom emphasized>
        <Msg id="feedback.aside.text" />
      </P>
      <P>
        <Msg id="feedback.aside.perex" />
      </P>
      <Divider sx={{ my: 3 }} />
      {isPending ? (
        <Loaders.Skeleton />
      ) : (
        <>
          {stats ? <ProgressStats items={stats} /> : null}
          {collected ? (
            <>
              <Divider sx={{ my: 3 }} />
              <P gutterBottom emphasized>
                <Msg id="feedback.aside.collected" />
              </P>
              <LinearProgressStyled
                variant="determinate"
                value={
                  !collected.total
                    ? 0
                    : (100 * collected.count) / collected.total
                }
                sx={{ my: 2 }}
              />
              <P>
                {collected.count}/{collected.total}
              </P>
            </>
          ) : null}
          {children}
        </>
      )}
    </ScrollableRightMenu>
  );
};
